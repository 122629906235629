<template>
  <div>
    <div class="wz">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>企业管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="box">
      <div class="serarch">
        <div class="searchleft">
          <el-form :inline="true" :model="searchform" class="demo-form-inline">
            <el-form-item class="input-item">
              <el-input
                placeholder="请输入公司名/手机号"
                v-model.trim="inputSeache"
                style="width:180px;"
                @keyup.enter.native="
                  pageNum = 1;
                  getCompanyMember();
                "
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search lh"
                  @click="
                    pageNum = 1;
                    getCompanyMember();
                  "
                ></i>
              </el-input>
            </el-form-item>
            <el-form-item class="input-item">
              <el-select
                v-model="searchLegalName"
                clearable
                filterable
                remote
                placeholder="请输入招聘负责人姓名"
                :loading="loading2"
                style="width: 150px"
                :remote-method="remoteSearchLegal"
                @change="
                  pageNum = 1;
                  getCompanyMember();
                "
              >
                <el-option
                  v-for="item in options2"
                  :key="item.id"
                  :label="item.legalName"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.legalName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.legalIdNumber
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="userStatus"
                style="width:120px;"
                @change="
                  pageNum = 1;
                  getCompanyMember();
                "
                clearable
                placeholder="全部"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="未冻结" value="0"></el-option>
                <el-option label="已冻结" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="企业认证">
              <el-select
                v-model="authInfoStatus"
                style="width:120px;"
                @change="
                  pageNum = 1;
                  getCompanyMember();
                "
                clearable
                placeholder="全部"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="未填写" value="0"></el-option>
                <el-option label="已认证" value="1"></el-option>
                <el-option label="已拒绝" value="2"></el-option>
                <el-option label="认证中" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="legalStatus"
                style="width:140px;"
                @change="
                  pageNum = 1;
                  getCompanyMember();
                "
                clearable
                placeholder="招聘负责人状态"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="已认证" value="1"></el-option>
                <el-option label="已拒绝" value="2"></el-option>
                <el-option label="待审核" value="3"></el-option>
                <!-- <el-option label="未认证" value="0"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="companyFamous"
                style="width:150px;"
                @change="
                  pageNum = 1;
                  getCompanyMember();
                "
                clearable
                placeholder="请选择企业级别"
              >
                <el-option label="名企" :value="1"></el-option>
                <el-option label="普通" :value="0"></el-option>
                <el-option label="实力雇主" :value="2"></el-option>
                <el-option label="优秀企业" :value="3"></el-option>
                <el-option label="外出" :value="7"></el-option>
                <el-option label="国企" :value="8"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-date-picker
              v-model="value1"
              type="daterange"
              clearable
              range-separator="至"
              value-format="yyyy-MM-dd"
              @change="changeTime"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="searchright">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="addUserBtn"
            class="mybotton"
            >添加企业账号</el-button
          >
        </div>
      </div>
      <div class="list">
        <el-table
          :data="tableData"
          :header-cell-style="{
            'font-weight': 'bold',
            color: '#555555',
            padding: '6px 0',
          }"
          style="width: 100%"
          v-loading="loading"
          row-key="id"
          border
          stripe
        >
          <el-table-column prop="id" align="center" label="ID" width="100">
          </el-table-column>
          <el-table-column
            prop="headImg"
            align="center"
            label="LOGO"
            width="100"
          >
            <template slot-scope="scope">
              <show-img
                v-if="scope.row.logo"
                :url="scope.row.logo"
                width="300"
                wid="40px"
              ></show-img>
              <show-img v-else :url="LogoUrl" width="300" wid="40px"></show-img>
            </template>
          </el-table-column>
          <el-table-column
            prop="companyName"
            align="center"
            label="企业基本信息"
            width="200"
          >
            <template slot-scope="scope">
              <el-tag v-if="!scope.row.companyName" type="info"
                >暂未填写</el-tag
              >
              <div class="companyName-layout" v-else>
                <el-link
                  style="width: 150px"
                  slot="reference"
                  @click="toCompanyDetail(scope.row.id)"
                  >{{ scope.row.companyName }}
                </el-link>
                <el-tooltip
                  v-if="scope.row.memberBaseInfoId != 0"
                  style="width: 20px"
                  effect="dark"
                  content="该企业基本信息已填写"
                  placement="top"
                >
                  <el-button
                    type="info"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-left: 5px;
                      padding: 0;
                    "
                    circle
                  >
                    <i class="el-icon-check"></i>
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" align="center" 
            width="150" label="企业认证信息">
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.authInfoStatus == 1"
                type="success"
                >已认证</el-tag
              >
              <el-tag
                v-else-if="scope.row.authInfoStatus == 0"
                type="info"
                >未填写</el-tag
              >
              <el-tag
                v-else-if="scope.row.authInfoStatus == 2"
                type="danger"
                >已拒绝</el-tag
              >
              <el-tag
                v-else-if="scope.row.authInfoStatus == 3"
                type="warning"
                >认证中</el-tag
              >
              <el-tooltip
                class="item"
                effect="dark"
                content="认证审核"
                placement="top"
              >
                <el-button
                  type="info"
                  style="
                    width: 20px;
                    height: 20px;
                    margin-left: 10px;
                    padding: 0;
                  "
                  circle
                >
                  <i
                    class="el-icon-edit"
                    @click="
                      toCompanyDetail1(scope.row.id, scope.row.memberAuthInfoId)
                    "
                  ></i>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="isFamous"
            align="center"
            label="企业级别"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.isFamous==0">普通</span>
              <span v-if="scope.row.isFamous==1">名企</span>
              <span v-if="scope.row.isFamous==2">实力雇主</span>
              <span v-if="scope.row.isFamous==3">优秀企业</span>
              <span v-if="scope.row.isFamous==7">外出企业</span>
              <span v-if="scope.row.isFamous==8">国企</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="companyName"
            align="center"
            label="招聘负责人"
            width="150"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.legalName }}
                <el-tooltip
                  v-if="scope.row.legalStatus == 1"
                  style=""
                  effect="dark"
                  content="该招聘负责人已认证"
                  placement="top"
                >
                  <el-button
                    type="info"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-left: 5px;
                      padding: 0;
                    "
                    circle
                  >
                    <i class="el-icon-check"></i>
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="userName"
            align="center"
            label="联系人"
            width="200"
          >
            <template slot-scope="scope">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                "
              >
                <span>{{ scope.row.userName }} </span>
                <span>{{ scope.row.phone }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            align="center"
            label="创建时间"
            width="200"
          >
          </el-table-column>
          <el-table-column prop="" align="center" label="是否冻结">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="true"
                :inactive-value="false"
                active-color="#404040"
                inactive-color="#b8b8b8"
                active-text=""
                inactive-text=""
                @change="changestatu($event, scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column prop="" width="300" align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="gotoSee(scope.row)"
                >查看报表</el-button
              >
              <el-button
                type="primary"
                @click="openChangeIsFamous(scope.row)"
                >更改</el-button
              >
              <el-button
                type="primary"
                @click="handleCreate(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                @click="deleteUser(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <app-page
        :total="total"
        :pageSize="pageSize"
        :current="pageNum"
        @handleCurrentChange="currentchange"
        @handleSizeChange="handchange"
      ></app-page>
    </div>
    <!-- 添加 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="canvel"
    >
      <el-form
        v-if="isChoseLegal"
        :label-position="labelPosition"
        label-width="100px"
        :model="formAddAdmin"
        :rules="rules"
        ref="formAddAdmin"
      >
        <!-- <el-form-item label="企业LOGO" prop="">
          <div class="block">
            <upload-img
              :modeldata="formAddAdmin"
              @changeurl="getimgurl"
              style="margin-top: 15px"
            ></upload-img>
          </div>
        </el-form-item> -->
        <!-- <el-form-item label="企业名称" prop="companyName">
          <el-input v-model="formAddAdmin.companyName"></el-input>
        </el-form-item>
        <el-form-item label="招聘负责人姓名" prop="legalName">
          <el-input v-model="formAddAdmin.legalName"></el-input>
        </el-form-item> -->
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="formAddAdmin.userName"></el-input>
        </el-form-item>
        <el-form-item label="登录手机号" prop="phone">
          <el-input v-model="formAddAdmin.phone"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input v-model="formAddAdmin.password"></el-input>
        </el-form-item>
        <el-form-item class="flex justify-content-end">
          <el-button
            @click="
              isChoseLegal = false;
              options = [];
              legalId = null;
            "
            >返 回</el-button
          >
          <el-button
            type="primary"
            v-preventReClick
            @click="addUser('formAddAdmin')"
            :loading="btnLoad"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
      <div
        v-else
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        请先选择招聘负责人：
        <el-select
          v-model="legalId"
          clearable
          filterable
          remote
          placeholder="请输入招聘负责人姓名"
          :loading="loading1"
          size="small"
          style="width: 150px"
          :remote-method="remoteMethod"
          @change="choseLegal"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.legalName"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <div style="margin-left: 10px; display: flex; align-items: center" v-if="roleId==1">
          (如未找到，请：<el-link @click="goLegal"
            >新建招聘负责人信息<i class="el-icon-edit"></i></el-link
          >)
        </div>
      </div>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible1"
      :close-on-click-modal="false"
      @close="canvel1"
    >
      <el-form
        :label-position="labelPosition"
        label-width="100px"
        :model="formAddAdmin1"
        :rules="rules"
        ref="formAddAdmin1"
      >
        <el-form-item label="登录手机号">
          <el-tag type="info">{{ formAddAdmin1.phone }}</el-tag>
        </el-form-item>
        <!-- <el-form-item label="公司LOGO" prop="">
          <div class="block">
            <upload-img
              :modeldata="formAddAdmin1"
              @changeurl="getimgurl"
              style="margin-top: 15px"
            ></upload-img>
          </div>
        </el-form-item> -->
        <!-- <el-form-item label="企业名称" prop="companyName">
          <el-input v-model="formAddAdmin1.companyName"></el-input>
        </el-form-item> -->
        <el-form-item label="联系人姓名" prop="userName">
          <el-input v-model="formAddAdmin1.userName"></el-input>
        </el-form-item>
        <!-- <el-form-item label="登录手机号" prop="phone">
          <el-input v-model="formAddAdmin1.phone"></el-input>
        </el-form-item> -->
        <el-form-item label="密码" prop="newPassword">
          <div v-if="isShowPwd">
            <el-input
              v-model="formAddAdmin1.newPassword"
              style="width: 600px"
            ></el-input>
            <el-button
              type="primary"
              style="margin-left: 10px"
              size="small"
              @click="
                isShowPwd = false;
                formAddAdmin1.newPassword = '';
              "
              class="mybotton"
              >返回</el-button
            >
          </div>
          <el-button
            v-else
            type="primary"
            style=""
            size="small"
            @click="isShowPwd = true"
            class="mybotton"
            >去修改</el-button
          >
        </el-form-item>
         <el-form-item label="宣传片" prop="">
           <upload-video :videoUrl="formAddAdmin1.companyPropaganda" @videoSuccess="videoSuccess" @changVideoStatus="changVideoStatus" :videoStatus="videoStatus"></upload-video>
         </el-form-item>
        <el-form-item label="企业Logo" prop="url">
          <upload-img
                  :modeldata="formAddAdmin1"
                  @changeurl="getCompanyImage"
                  style="margin-top: 15px"
          ></upload-img>
        </el-form-item>
        <el-form-item class="flex justify-content-end">
          <el-button @click="canvel1">取 消</el-button>
          <el-button
            type="primary"
            v-preventReClick
            @click="updateUser('formAddAdmin1')"
            :loading="btnLoad"
            >修改</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 详情展示 -->
    <el-dialog
      title="企业详情"
      :visible.sync="dialogVisible2"
      width="900px"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="12">
          <div class="text-item">公司名称：{{ companyInfo.companyName }}</div>
        </el-col>
        <el-col :span="12">
          <div class="text-item">
            公司行业：{{ companyInfo.companyIndustryName }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="text-item">
            公司性质：{{ companyInfo.companyNatureName }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="text-item">公司规模：{{ companyInfo.companyScale }}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="text-item">
            所在地区：{{ companyInfo.companyAreaName }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="text-item">
            联系人：{{ companyInfo.companyContactsName }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="text-item">
            联系人电话：{{ companyInfo.companyContactsPhone }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="text-item">
            公司固定电话：{{ companyInfo.companyFixedTelephone }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="text-item">
            公司电子邮箱：{{ companyInfo.companyEmail }}
          </div>
        </el-col>
        <el-col :span="12" style="display: flex">
          <div style="width: 90px">公司简介：</div>
          <div
            style="
              width: 100%;
              height: 70px;
              overflow: auto;
              margin-bottom: 10px;
            "
          >
            {{ companyInfo.companyInfo }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <span>公司宣传片：</span>
          <div v-if="companyInfo.companyPropaganda" class="input_video">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
            ></video-player>
          </div>
          <el-tag type="info" v-else>无</el-tag>
        </el-col>
        <el-col :span="12">
          <span>公司环境介绍：</span>
          <div
            v-if="companyInfo.companyAmbientImgs"
            style="margin-top: 10px"
            class="company-img"
          >
            <div
              class="company-item"
              v-for="(item, index) in companyInfo.companyAmbientImgs"
              :key="index"
            >
              <el-avatar shape="square" :size="133" :src="item.url"></el-avatar>
              <!-- <img :src="item.url" alt=""> -->
              <div class="img-label">
                <i class="el-icon-zoom-in" @click="openImgBox(item)"></i>
              </div>
            </div>

            <!-- <el-upload
              action=""
              list-type="picture-card"
              :file-list="companyInfo.companyAmbientImgs"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove">
            </el-upload>
            <div style="'z-index':10000">
              <el-dialog :visible.sync="companyVisible">
                <img width="100%" :src="companyImageUrl" alt="">
              </el-dialog>
            </div> -->
            <!-- <el-image
              :src="companyInfo.companyAmbientImg"
              style="width: 320px; height: 180px"
              :preview-src-list=companyInfo.companyAmbientImg
              fit="cover"
            >
            </el-image> -->
          </div>
          <el-tag v-else type="info">无</el-tag>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 认证详情 -->
    <el-dialog
      title="企业详情"
      :visible.sync="dialogVisible3"
      width="800px"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancelAuth"
    >
      <el-row>
        <el-col :span="16">
          <el-row>
            <el-col :span="14">
              <div class="text-item">公司名称：{{ authInfo.companyName }}</div>
            </el-col>
            <el-col :span="10">
              <div class="text-item">
                招聘负责人姓名：{{ legalInfo.legalName }}
                <el-tag v-if="legalInfo.status == 1" type="success" size="mini"
                  >已认证</el-tag
                >
                <el-tag
                  v-else-if="legalInfo.status == 0"
                  type="info"
                  size="mini"
                  >认证中</el-tag
                >
                <el-tag
                  v-else-if="legalInfo.status == 2"
                  type="danger"
                  size="mini"
                  >认证拒绝</el-tag
                >
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <div class="text-item">
                公司营业执照编号：{{ authInfo.companyBusinessLicenseNumber }}
              </div>
            </el-col>
            <el-col :span="10">
              <div class="text-item">
                招聘负责人身份证号：{{ legalInfo.legalIdNumber }}
              </div>
            </el-col>
          </el-row>
          <el-row style="height: 200px">
            <el-col :span="14">
              公司营业执照：
              <div>
                <!-- <el-upload
                  class="upload-demo"
                  action=""
                  :on-remove="handleRemove"
                  :file-list="[authInfo.companyBusinessLicense]"
                  list-type="picture">
                </el-upload> -->
                <!-- <el-image
                  :src="authInfo.companyBusinessLicense"
                  style="width: 320px; height: 180px; margin-top: 10px"
                  :preview-src-list="[authInfo.companyBusinessLicense]"
                  fit="cover"
                >
                </el-image> -->
                <div class="certificate-img">
                  <el-image
                    shape="square"
                    :src="authInfo.companyBusinessLicense"
                    style="width: 285px; height: 180px"
                  ></el-image>
                  <div class="certificate-mask">
                    <i
                      class="el-icon-zoom-in"
                      @click="
                        openImgBoxWithoutUrl(authInfo.companyBusinessLicense)
                      "
                    ></i>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <el-row> 招聘负责人身份证： </el-row>
              <el-row style="width: 380px; height: 100px; margin-top: 10px">
                <el-col :span="12">
                  <!-- <el-image
                    :src="legalInfo.legalIdCardPre"
                    style="width: 160px; height: 90px"
                    :preview-src-list="[legalInfo.legalIdCardPre]"
                    fit="cover"
                  >
                  </el-image> -->
                  <div class="legal-img">
                    <el-image
                      shape="square"
                      :src="legalInfo.legalIdCardPre"
                      style="width: 160px; height: 90px"
                    ></el-image>
                    <div class="legal-mask">
                      <i
                        class="el-icon-zoom-in"
                        @click="openImgBoxWithoutUrl(legalInfo.legalIdCardPre)"
                      ></i>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <div class="legal-img">
                    <el-image
                      shape="square"
                      :src="legalInfo.legalIdCardAfter"
                      style="width: 160px; height: 90px"
                    ></el-image>
                    <div class="legal-mask">
                      <i
                        class="el-icon-zoom-in"
                        @click="
                          openImgBoxWithoutUrl(legalInfo.legalIdCardAfter)
                        "
                      ></i>
                    </div>
                  </div>
                  <!-- <el-image
                    :src="legalInfo.legalIdCardAfter"
                    style="width: 160px; height: 90px"
                    :preview-src-list="[legalInfo.legalIdCardAfter]"
                    fit="cover"
                  >
                  </el-image> -->
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6">
              <el-row> </el-row>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <div class="block">
              <div class="radio"></div>
              <el-timeline>
                <el-timeline-item
                  :timestamp="authInfo.createTime"
                  :color="'#0bbd87'"
                  >填写完成
                </el-timeline-item>
                <el-timeline-item
                  :timestamp="authInfo.createTime"
                  :color="'#0bbd87'"
                  >提交认证
                </el-timeline-item>
                <el-timeline-item v-if="authInfo.status == 3" :color="'#efb336'"
                  >认证中
                </el-timeline-item>
                <el-timeline-item
                  v-else-if="authInfo.status == 1"
                  :timestamp="authInfo.updateTime"
                  :color="'#0bbd87'"
                  >认证通过
                </el-timeline-item>
                <el-timeline-item
                  v-else-if="authInfo.status == 2"
                  :timestamp="authInfo.updateTime"
                  :color="'#eb3409'"
                  >已拒绝
                </el-timeline-item>
              </el-timeline>
            </div>
          </el-row>
          <el-row>
            <el-button
              v-if="authInfo.status == 3"
              type="success"
              size="mini"
              @click="changeStatus(authInfo.id, 1)"
              >通过</el-button
            >
            <el-button
              v-if="authInfo.status == 3"
              type="danger"
              size="mini"
              @click="changeStatus(authInfo.id, 2)"
              >拒绝</el-button
            >
          </el-row>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div v-if="memberRecord[0]">
        <el-tag type="success" style="margin-bottom: 10px"
          >企业认证历史变更版本：</el-tag
        >
        <el-row style="display: flex; width: 100%; height: 100px">
          <div style="" v-for="(item, index) in memberRecord" :key="index">
            <el-link
              @click="drawerClick(index)"
              type="primary"
              style="margin-right: 30px"
            >
              版本：{{ item.createTime }}
            </el-link>
          </div>
        </el-row>
      </div>
    </el-dialog>
    <el-drawer
      :title="drawerData.createTime"
      :with-header="false"
      :visible.sync="drawer"
      :modal="false"
    >
      <div class="drawer">
        <div
          style="
            display: flex;
            align-items: center;
            width: 100%;
            height: 80px;
            background-color: #3968d0;
            margin-bottom: 20px;
          "
        >
          <span
            style="
              font-size: 20px;
              margin-left: 10px;
              font-family: PingFang SC;
              font-weight: 800;
              color: #ffffff;
            "
          >
            版本：{{ drawerData.createTime }}
          </span>
        </div>
        <div class="drawerLine">企业名称：{{ drawerData.companyName }}</div>
        <div class="drawerLine">
          企业营业执照编号：{{ drawerData.companyBusinessLicenseNumber }}
        </div>
        <div>
          <div style="width: 100%; padding: 0 20px">企业营业执照：</div>
          <!-- <el-image
            :src="drawerData.companyBusinessLicense"
            style="width: 320px; height: 180; margin: 20px"
            :preview-src-list="[drawerData.companyBusinessLicense]"
            fit="cover"
          >
          </el-image> -->
          <div class="time-line-img">
            <el-image
              shape="square"
              :src="drawerData.companyBusinessLicense"
              style="width: 320px; height: 180px"
            ></el-image>
            <div class="time-line-mask">
              <i
                class="el-icon-zoom-in"
                @click="openImgBoxWithoutUrl(drawerData.companyBusinessLicense)"
              ></i>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
      </div>
    </el-drawer>
    <el-dialog
      title="更改企业级别"
      :visible.sync="changeIsFamousVisible"
      width="600px"
    >
      <el-radio-group v-model="famousForm.isFamous">
        <el-radio :label="1">名企</el-radio>
        <el-radio :label="2">实力雇主</el-radio>
        <el-radio :label="3">优秀企业</el-radio>
        <el-radio :label="0">普通</el-radio>
        <el-radio :label="7">外出</el-radio>
        <el-radio :label="8">国企</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeIsFamousVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveFamousVisible">确 定</el-button>
      </span>
    </el-dialog>
    <div>
      <el-dialog
        title="预览图片"
        :visible.sync="companyImgVisible"
        width="550px"
      >
        <div class="box-img">
          <!-- <img :src="companyImgPath" alt="" class="companyImg-path" /> -->
          <el-image
          style="width: 500px; "
          :src="companyImgPath"
          :preview-src-list="companyImgPath.split(',')"
          fit="cover"></el-image>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import _api from "@/api/index";
import apiUrl from "@/api/public";
import UploadVideo from '../../components/uploadVideo/Index'
import companyRequest from "../../api/company";
let checkMobile = (rule, value, cb) => {
  const regMobile = /^1[0-9]{10}$/;

  if (regMobile.test(value)) {
    return cb();
  }
  cb(new Error("请输入合法的手机号"));
};
let checkpassword = (rule, value, cb) => {
  // const regPassword = /^[a-z0-9]{6,16}$/
  const regPassword = /^[0-9a-zA-Z]{6,12}$/g;
  if (regPassword.test(value)) {
    return cb();
  }
  cb(new Error("密码只能由6-12位字母或数字组成"));
};
export default {
  components: {
        UploadVideo,
    },
  data() {
    return {
      roleId:localStorage.getItem("roleId"),
      value1:[],
      LogoUrl:
        "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      searchform: {
        jobName: "",
        status: "",
      },
      changeIsFamousVisible: false, //更改企业类别弹出框
      tableData: [], //权限列表数据
      defaultArr: [], //默认展开行
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      formAddAdmin: {
        companyName: "",
        legalName: "",
        userName: "",
        phone: "",
        password: "",
        newUserRoleId: null,
        url: "",
      },
      formAddAdmin1: {
        companyName: "",
        legalName: "",
        userName: "",
        newPassword: "",
        newUserRoleId: null,
        url: "",
        companyPropaganda: "",
        coverPicture: "",
      },
      rules: {
        userName: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { validator: checkMobile, trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { validator: checkpassword, trigger: "blur" },
        ],
      },
      list: [],
      data: [],
      loading: false,

      labelPosition: "right", //控制表单对齐方式
      btnLoad: false,
      id: null,
      famousForm: {
        //更改企业级别
        memberId: null, //企业id
        isFamous: null, //企业级别 1名企 0普通
      },
      companyImgVisible: false, //企业基本信息图片提示框
      companyImgPath: "", //企业基本信息图片提示框
      userInfo: {},
      total: 0,
      pageNum: 1,
      pageSize: 10,
      userName: "",
      account: "",
      roleId: 1,
      inputSeache: "",
      roleList: [],
      userRoleId: "",
      userStatus: null,
      authInfoStatus: null,
      isShowPwd: false,
      companyInfo: {},
      authInfo: {},
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: "", //url地址
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      memberRecord: [],
      drawer: false,
      drawerData: {},
      isChoseLegal: false,
      legalName: "",
      legalName2: "",
      legalId: null,
      loading1: false,
      loading2: false,
      options: [],
      options2: [],
      legalInfo: {},
      legalStatus: null,
      companyFamous: null,
      searchLegalName: null,
      videoStatus: false,
      statusFlag: 1, //1视频上传中、2视频上传成功、3视频上传失败
      startTime:null,
      endTime:null,
    };
  },
  created() {
    this.userInfo = window.localStorage.getItem("userInfo");
    this.pageNum=this.getContextData("currentPageuser") || 1;
    this.getCompanyMember();
  },
  methods: {
    changeTime(){
      if(this.value1 && this.value1.length>0){
        this.startTime=this.value1[0]
        this.endTime=this.value1[1]
      }else{
        this.startTime=null
        this.endTime=null
      }
      console.log(this.startTime)
      console.log(this.endTime)
      this.pageNum=1
      this.getCompanyMember();

    },
    setContextData: function(key, value) { 
        if(typeof value == "string"){
            sessionStorage.setItem(key, value);
        }else{
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    },
    // 从sessionStorage取值
    getContextData: function(key){
        const str = sessionStorage.getItem(key);
        if( typeof str == "string" ){
            try{
                return JSON.parse(str);
            }catch(e) {
                return str;
            }
        }
        return;
    },

    gotoSee(row){
      this.$router.push({path:"/datareport/index",query:{mid:row.id,t:row.companyName}})
    },
    changVideoStatus(states) {
            if (states === 'upload') {
                this.videoStatus = true
                this.statusFlag = 1
            } else if (states === 'success') {
                this.statusFlag = 2
                setTimeout(() => {
                    this.videoStatus = false
                }, 1500)
            } else if (states === 'error') {
                this.statusFlag = 3
                setTimeout(() => {
                    this.videoStatus = false
                }, 1500)
            }
        },
    videoSuccess(val) {
            this.formAddAdmin1.companyPropaganda = val[0]
            this.formAddAdmin1.coverPicture = val[1]
            // console.log('companyPropaganda', this.companyForm.companyPropaganda)
        },
    getCompanyImage(data){  // 获取企业上传的头像
      console.log("获取到的企业上传的头像",data)
      this.formAddAdmin1.url = data
    },
    openChangeIsFamous(row) {
      // console.log('row', row)
      // if (row.isFamous) {
      //   this.famousForm.isFamous = 1;
      // } else {
      //   this.famousForm.isFamous = 0;
      // }
      this.famousForm.isFamous = row.isFamous;
      this.famousForm.memberId = row.id;
      this.changeIsFamousVisible = true;
    },
    //保存企业类别 1是名企 为普通
    saveFamousVisible() {
      companyRequest.changeIsFamous(this.famousForm).then((res) => {
        if (res.code === 200) {
          this.$message.success("更改成功~");
          this.getCompanyMember();
          this.famousForm.memberId = null;
          this.famousForm.isFamous = null;
          this.changeIsFamousVisible = false;
        }
      });
    },
    changestatu(status, row) {
      //切换发布状态
      _api
        .post(apiUrl.lockMember, {
          memberId: row.id,
          status: status,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getupData(url) {
      this.ruleForm.imgUrl = url;
    },
    getimgurl(data) {
      this.formAddAdmin.url = data;
    },
    // 取消
    canvel() {
      this.dialogVisible = false;
      this.isChoseLegal = false;
      this.legalName = null;
      this.legalId = null;
      this.options = [];
      this.formAddAdmin.phone = this.formAddAdmin.userName = this.formAddAdmin.password = this.formAddAdmin.newUserRoleId = this.formAddAdmin.url =
        "";
    },
    canvel1() {
      this.dialogVisible1 = false;
      this.formAddAdmin1.newPassword = ""
      this.isShowPwd = false;
    },
    addUserBtn() {
      this.dialogVisible = true;
    },
    //添加用户
    addUser(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.formAddAdmin;
          const data = {
            phone: form.phone,
            legalId: this.legalId,
            companyName: form.companyName,
            userName: form.userName,
            password: form.password,
            roleId: 2,
            headImg: form.url,
          };
          _api
            .get(apiUrl.checkPhone, {
              phone: data.phone,
            })
            .then((res) => {
              if (res.success) {
                this.addUser1(data);
              }
            })
            .catch((err) => {
              this.$message({
                message: err,
                type: "warning",
              });
              console.log(err);
            });
        } else {
          this.$message.error("信息未填写完整，请检查！");
          return false;
        }
      });
    },
    addUser1(data) {
      _api
        .post(apiUrl.addMember, data)
        .then((res) => {
          if (res.success) {
            this.canvel();
            this.getCompanyMember();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateUser(formName) {
      const regPassword = /^[0-9a-zA-Z]{6,12}$/g;
      let result = regPassword.test(this.formAddAdmin1.newPassword);

      if(!this.formAddAdmin1.url){
        this.$message.error("企业Logo为空，请检查");
        return;
      }

      if (result || !this.formAddAdmin1.newPassword) {
        this.btnLoad = true;
        this.$refs[formName].validate((valid) => {
          const data = {
            id: this.id,
            userName: this.formAddAdmin1.userName,
            password: this.formAddAdmin1.newPassword,
            companyPropaganda: this.formAddAdmin1.companyPropaganda,
            coverPicture: this.formAddAdmin1.coverPicture,
            roleId: 2,
            // headImg: this.formAddAdmin1.url,
            logo:this.formAddAdmin1.url
          };
          if (valid) {
            _api
              .post(apiUrl.updateMember, data)
              .then((res) => {
                if (res.success) {
                  this.$message.success("修改成功");
                  this.btnLoad = false;
                  this.canvel1();
                  this.getCompanyMember();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.$message.error("信息未填写完整，请检查！");
            return false;
          }
        });
      } else {
        this.$message.error("密码只能由6-12位字母或数字组成");
      }
    },
    getCompanyMember() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userName: this.inputSeache,
        legalStatus: this.legalStatus,
        status: this.userStatus,
        authInfoStatus: this.authInfoStatus,
        legalId: this.searchLegalName,
        isFamous: this.companyFamous,
        startTime:this.startTime,
        endTime:this.endTime
      };
      _api
        .post(apiUrl.queryCompanyMember, data)
        .then((res) => {
          this.loading = false;
          if (res.success) {
            console.log(res);
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCreate(id) {  // 编辑
      this.id = id;
      this.dialogVisible1 = true;
      this.selectMemberById(id);
    },
    selectMemberById(id) {
      _api
        .get(apiUrl.selectMemberById, {
          memberId: id,
        })
        .then((res) => {
          if (res.success) {
            this.formAddAdmin1.userName = res.data.userName;
            this.formAddAdmin1.phone = res.data.phone;
            this.formAddAdmin1.password = res.data.password;
            this.formAddAdmin1.newUserRoleId = res.data.roleId;
            this.formAddAdmin1.url = res.data.logo;
            this.formAddAdmin1.companyPropaganda = res.data.companyPropaganda;
            this.formAddAdmin1.coverPicture = res.data.coverPicture;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteUser(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _api
            .get(apiUrl.deleteMember, {
              memberId: id,
            })
            .then((res) => {
              if (res.success) {
                this.$message.success("删除成功");
                this.getCompanyMember();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handchange(data) {
      //分页Size变化
      this.pageSize = data;
      this.pageNum = 1;
      this.getCompanyMember();
    },
    currentchange(data) {
      //当前页变化
      this.pageNum = data;
      this.setContextData("currentPageuser",this.pageNum)
      this.getCompanyMember();
    },
    toCompanyDetail(id) {
      //企业基本信息页
      _api
        .get(apiUrl.selectMemberBaseInfoById, {
          memberId: id,
        })
        .then((res) => {
          if (res.success) {
            console.log(res);
            this.dialogVisible2 = true;
            if (res.data.companyAmbientImg) {
              res.data.companyAmbientImg = res.data.companyAmbientImg.split(
                ","
              );
            } else {
              res.data.companyAmbientImg = [];
            }
            console.log("data", res.data);
            this.companyInfo = res.data;
            this.companyInfo.companyAmbientImgs = this.companyInfo.companyAmbientImg.map(
              (item) => {
                return {
                  url: item,
                };
              }
            );
            this.playerOptions.sources[0].src = res.data.companyPropaganda;
            console.log("companyInfo", this.companyInfo);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //预览图片
    openImgBox(row) {
      this.companyImgVisible = true;
      this.companyImgPath = row.url;
    },
    //预览图片
    openImgBoxWithoutUrl(row) {
      this.companyImgVisible = true;
      this.companyImgPath = row;
    },
    toCompanyDetail1(id, authId) {
      //认证详情页
      if (authId == 0) {
        this.$message.error("该企业尚未填写认证信息");
      } else {
        this.dialogVisible3 = true;
        _api
          .get(apiUrl.queryByMemberId, {
            memberId: id,
          })
          .then((res) => {
            if (res.success) {
              console.log(1, res);
              this.authInfo = res.data;
              this.legalInfo = res.data.legal;
            }
          });
        _api
          .get(apiUrl.getMemberRecord, {
            memberId: id,
          })
          .then((res) => {
            if (res.success) {
              this.memberRecord = res.data;
            }
          });
      }
    },
    cancelAuth() {
      this.dialogVisible3 = false;
      this.drawerData = {};
      this.memberRecord = [];
      this.authInfo = {};
    },
    changeStatus(id, status) {
      this.$confirm("确定执行该操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        _api
          .post(apiUrl.AuthChangeStatus, {
            memberAuthInfoId: id,
            status: status,
          })
          .then((res) => {
            if (res.success) {
              this.$message.success("操作成功");
              this.cancelAuth();
              this.getCompanyMember();
            }
          });
      });
    },
    drawerClick(index) {
      this.drawer = true;
      this.drawerData = this.memberRecord[index];
      console.log(this.drawerData);
    },
    remoteMethod(query) {
      this.legalName = query;
      if (query !== "") {
        this.loading1 = true;
        _api
          .get(apiUrl.findLegal, {
            name: query,
          })
          .then((res) => {
            if (res.success) {
              let resList = [];
              res.data.forEach((item) => {
                resList.push(item);
              });
              setTimeout(() => {
                this.loading1 = false;
                this.options = resList.filter((i) => {
                  return i.legalName.indexOf(query) > -1;
                });
              }, 200);
            }
          });
      } else {
        this.options = [];
      }
    },
    remoteSearchLegal(query) {
      this.legalName2 = query;
      if (query !== "") {
        this.loading2 = true;
        _api
          .get(apiUrl.queryLegalList, {
            name: query,
          })
          .then((res) => {
            if (res.success) {
              console.log(1, res);
              let resList = [];
              res.data.forEach((item) => {
                resList.push(item);
              });
              setTimeout(() => {
                this.loading2 = false;
                this.options2 = resList.filter((i) => {
                  return i.legalName.indexOf(query) > -1;
                });
              }, 200);
            }
          });
      } else {
        this.options = [];
      }
    },
    goLegal() {
      this.$router.push({
        path: "/user/legalManage",
        query: { state: true, name: this.legalName },
      });
    },
    choseLegal(event) {
      this.isChoseLegal = true;
    },
  },
};
</script>
<style>
.el-loading-spinner .path {
  stroke: #fe8196;
}
.el-switch__label {
  color: #bdbdbd;
}
.el-switch__label.el-switch__label--right.is-active {
  color: #5cb87a;
}
.el-switch__label.el-switch__label--left.is-active {
  color: #fe8496;
}
</style>
<style lang="scss" scoped>
@import "../../assets/css/booth.css";
.serarch {
  overflow: hidden;
}
.text-item {
  width: 100%;
  height: 50px;
}
.card {
  width: 100%;
  display: flex;
}
.card-left {
  width: 50%;
}
.card-right {
  width: 50%;
}
.input_video {
  width: 320px;
  height: 180px;
  margin-top: 10px;
}
.drawer {
  width: 100%;
}
.drawerLine {
  width: 100%;
  height: 50px;
  padding: 0 20px;
}
.box-img {
  display: flex;
  justify-content: center;
}
.companyImg-path {
  max-width: 500px;
}
.company-img {
  display: flex;
  flex-wrap: wrap;
  .company-item {
    position: relative;
    box-sizing: border-box;
    padding: 0 10px 10px 0;
    width: 33.33333%;
    &:hover {
      .img-label {
        display: block;
      }
    }
    img {
      width: 133px;
      height: 133px;
    }
    .img-label {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-content: center;
      position: absolute;
      width: 133px;
      height: 133px;
      display: none;
      background: rgba($color: #000000, $alpha: 0.6);
      top: 0;
      left: 0;
      .el-icon-zoom-in {
        width: 100%;
        height: 100%;
        line-height: 133px;
        text-align: center;
        font-size: 20px;
        color: #fff;
      }
    }
  }
}
.certificate-img {
  box-sizing: border-box;
  padding-right: 10px;
  position: relative;
  margin-top: 10px;
  width: 295px;
  height: 180px;
  &:hover {
    .certificate-mask {
      display: block;
    }
  }
  .certificate-mask {
    position: absolute;
    cursor: pointer;
    display: none;
    width: 288px;
    height: 180px;
    background: rgba($color: #000, $alpha: 0.6);
    left: 0;
    top: 0;

    .el-icon-zoom-in {
      width: 100%;
      height: 100%;
      line-height: 180px;
      text-align: center;
      font-size: 20px;
      color: #fff;
    }
  }
}
.legal-img {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 160px;
  height: 90px;
  &:hover {
    .legal-mask {
      display: block;
    }
  }
  .legal-mask {
    display: none;
    cursor: pointer;
    position: absolute;
    width: 160px;
    height: 90px;
    background: rgba($color: #000, $alpha: 0.6);
    left: 0;
    top: 0;
    .el-icon-zoom-in {
      width: 100%;
      height: 100%;
      line-height: 90px;
      text-align: center;
      font-size: 20px;
      color: #fff;
    }
  }
}
.time-line-img {
  position: relative;
  width: 320px;
  height: 180px;
  margin: 20px;
  &:hover {
    .time-line-mask {
      display: block;
    }
  }
  .time-line-mask {
    display: none;
    cursor: pointer;
    position: absolute;
    width: 320px;
    height: 180px;
    background: rgba($color: #000, $alpha: 0.6);
    left: 0;
    top: 0;
    .el-icon-zoom-in {
      width: 100%;
      height: 100%;
      line-height: 180px;
      text-align: center;
      font-size: 20px;
      color: #fff;
    }
  }
}
.companyName-layout {
  display: flex;
}
</style>
